import { Card, Carousel } from "react-bootstrap";
import styled from "styled-components";
import First_image from "../../assests/section_1/slider-1.jpg";
import { colors } from "../../const/theme";
import card_bg from "../../assests/section_2/pattern-8.svg";

export const FirstSectionWrappper = styled(Carousel)``;

export const CarouselImage = styled(Carousel.Item)`
  background-image: url(${First_image});
  position: relative;
  background-color: #000324;
  background-repeat: no-repeat;
  padding: 200px 92px 150px 92px;
  background-position: center center;
  background-size: cover;
  z-index: 2;
  height: 900px;
`;

//section_2

export const SecondSectionWrapper = styled.section`
  background-color: rgb(14, 13, 12);
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 120px 0;
`;

export const FloatingPng = styled.img`
  position: absolute;
  width: 100px;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardContiner = styled(Card)`
  position: relative;
  width: 300px;
  height: 400px;
  background-color: transparent;
  img {
    position: absolute;
    top: 30px;
    object-fit: contain;
  }
`;

export const CardBackgroundSvg = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -70px;
  width: 140px;
  top: 0;
  bottom: 0;
  transition: all 500ms ease;
  background: url(${card_bg}) center repeat;
`;

//section_2

export const ThirdSectionWrapper = styled.section`
  background-color: #171819;
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 120px 0;
`;
