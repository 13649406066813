import { CardContiner, CardBackgroundSvg } from "./subComponents";
import card_image from "../../assests/section_2/offer-image-1.jpg";
import { Subtitles, TitleWrapper } from "../../typography";
import { fontfamily, colors } from "../../const/theme";

interface SectionCardWrapperProps {
  title: string;
}

export const SectionCardWrapper = ({ title }: SectionCardWrapperProps) => {
  return (
    <CardContiner>
      <CardBackgroundSvg />
      <img src={card_image} alt="" />
      <TitleWrapper margin="430px 0 0 ">
        <Subtitles
          font="35px"
          fontFamily={fontfamily.fontFamilyForum}
          lineHeight="1.20em"
          fontWeight={400}
          color={colors?.white}
        >
          {title}
        </Subtitles>
      </TitleWrapper>
    </CardContiner>
  );
};
