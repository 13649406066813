import styled from "styled-components";
import footer_bg from "../../assests/footer/image-4.jpg";
import card_bg from "../../assests/section_2/pattern-8.svg";
import { fontfamily } from "../../const/theme";

export const FooterWrapper = styled.footer`
  padding: 90px 0 0;
  position: relative;
  background-image: url(${footer_bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: 100% 0;
  box-shadow: 0 0 0 0 #ffffff;
  .footer_text {
    color: rgb(167, 167, 167);
    position: relative;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    font-family: ${fontfamily.fontFamilyDMS};
  }
`;

export const FooterCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 400px;
`;

export const FooterCardContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : "300px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterAddressWrapper = styled.div`
  position: relative;
`;
export const CardBackgroundSvg = styled.div`
  position: absolute;
  width: 450px;
  height: 400px;
  transition: all 500ms ease;
  background: url(${card_bg});
`;
