import styled from "styled-components";
import { colors, fontfamily } from "../../const/theme";

export const MenuWrapper = styled.div`
  background-color: #171819;
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 90px 0;
  position: relative;
`;

export const MenuCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
`;

export const MenuContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  img {
    border-radius: 9px;
    object-fit: contain;
  }
  span {
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 600;
    font-family: ${fontfamily.fontFamilyForum};
  }
`;
