import {   Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { WrapperProsp } from "./types";

export const PageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  transition: filter 0.5s ease;
`;

export const SectionContainer = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  max-width: 1150px;
  margin: 0 auto;
  @media (min-width: 1400px) {
    max-width: 1356px;
  }
`;

export const RowWrapper = styled(Row)<WrapperProsp>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : null)};
  position: relative;
  margin-right: calc(1.5rem / -2);
  margin-left: calc(1.5rem / -2);
`;

export const ColumnWrapper = styled(Col)<WrapperProsp>`
  position: relative;
  flex: 0 0 auto;
  width: ${({ width }) => (width ? width : "50%")};
  display: flex;
  margin: 0 auto; //dou
`;

export const ImageBannerContainer = styled.div<{ background: string }>`
  position: relative;
  z-index: 2;
  padding-top: 140px;
  padding-bottom: 140px;
  background-image: ${({ background }) =>
    background ? `url(${background})` : null};
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 600px;
`;

export const ImageOpacityReducer = styled.div<{ background?: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.60;
  background-color: ${({ background }) =>
    background ? background : "rgba(0,0,0)"};
`; 


export const BannerWrapper = styled.div`
  height: auto;
`;