import React, { ReactElement } from "react";
import { MenuContainer } from "./subcomponents";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import { colors, fontfamily } from "../../const/theme";

interface MenuCardsProps {
  dishImg: string;
  dishName: string;
  dishPrice: string;
  dishDes: string;
}

const MenuCards = ({
  dishDes,
  dishImg,
  dishName,
  dishPrice,
}: MenuCardsProps): ReactElement => {
  return (
    <MenuContainer>
      <img src={dishImg} alt="" />
      <TitleWrapper>
        <Title
          font="25px"
          fontFamily={fontfamily.fontFamilyForum}
          lineHeight="1.20em"
          fontWeight={600}
          color={colors?.white}
        >
          {dishName}
        </Title>
        <Subtitles
          font="14px"
          fontFamily={fontfamily.fontFamilyDMS}
          lineHeight="1.20em"
          fontWeight={400}
          color={"rgb(167,167,167)"}
        >
          {dishDes}
        </Subtitles>
        <span>$ {dishPrice}</span>
      </TitleWrapper>
    </MenuContainer>
  );
};

export default MenuCards;
