import { Route, Routes } from "react-router";
import { Home, About, Contact, Menu } from "./pages";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/menu-lists" element={<Menu />} />
    </Routes>
  );
}

export default App;
