export const colors = {
  primary: "#EF5B2B",
  secondary: "#37AB49",
  white: "#fff",
  black: "#000",
};

export const fontfamily = {
  fontFamilyDMS: "DM Sans, sans-serif",
  fontFamilyForum: "Forum, serif",
};
