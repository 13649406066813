import { ReactElement, useState } from "react";
import {
  PageWrapper,
  Header,
  SectionContainer,
  RowWrapper,
  ColumnWrapper,
  PrimaryButton,
} from "../../components";
import {
  CardWrapper,
  CarouselImage,
  FirstSectionWrappper,
  FloatingPng,
  SecondSectionWrapper,
  ThirdSectionWrapper,
} from "./subComponents";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import seperator_img from "../../assests/separator.png";
import { colors, fontfamily } from "../../const/theme";
import { SectionCardWrapper } from "./helper";
import floation_img from "../../assests/section_2/Hamburger-bro.png";
import Footer from "../Footer";

const Home = (): ReactElement => {
  const [position, setPosition] = useState(0);
  const onScroll = (event: any) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    setPosition(scrollPosition);
    console.log(scrollPosition);
  };
  document.addEventListener("scroll", onScroll);
  return (
    <PageWrapper>
      <Header
      // position={position >= 500 ? "fixed" : "absolute"}
      // height={position >= 500 ? "90px" : "100px"}
      // background={position >= 500 ? "#000324" : "transparent"}
      // scrollPosition={position >= 500}
      />
      <FirstSectionWrappper indicators={false} controls={true} fade={true}>
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors.primary}
            >
              AMAZING & DELICIOUS
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              For the love of <br />
              delicious food
            </Title>{" "}
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              TRADATIONAL & HYGINE
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              Where every meal <br />
              is an adventure.
            </Title>
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>{" "}
        <CarouselImage interval={2000}>
          <TitleWrapper margin="90px 0 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              DELIGHTFUL EXPERIENCE
            </Subtitles>
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="80px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
              className="mt-2"
            >
              Crafted with passion, <br />
              served with love.
            </Title>
            <Subtitles
              font="20px"
              fontFamily={fontfamily.fontFamilyDMS}
              lineHeight="80px"
              fontWeight={400}
              color={colors?.white}
            >
              Come with family & feel the joy of mouthwatering food
            </Subtitles>
          </TitleWrapper>
        </CarouselImage>
      </FirstSectionWrappper>
      <SecondSectionWrapper>
        <SectionContainer>
          <TitleWrapper>
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              FLAVOR FOR ROYALTY
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="60px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="1.20em"
              fontWeight={400}
              color={colors?.white}
              className="mt-4"
            >
              We Offer Top Notch
            </Title>
          </TitleWrapper>
          <CardWrapper>
            <SectionCardWrapper title="Breakfast" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Title
                font="16px"
                fontFamily={fontfamily.fontFamilyDMS}
                lineHeight="30px"
                fontWeight={400}
                textAlign="center"
                color={colors?.white}
                className="mt-2 mb-4"
              >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. <br />
                Ipsa eum fugiat officiis maxime inventore,repudiandae e
                <br /> os neque excepturi.
              </Title>
              <SectionCardWrapper title="Lunch" />
            </div>
            <SectionCardWrapper title="Dinner" />
          </CardWrapper>
        </SectionContainer>
      </SecondSectionWrapper>
      <ThirdSectionWrapper>
        <SectionContainer>
          <RowWrapper>
            <ColumnWrapper>
              <TitleWrapper>
                <Subtitles
                  font="12px"
                  fontFamily={fontfamily.fontFamilyDMS}
                  letterSpacing="4px"
                  fontWeight={700}
                  color={colors?.primary}
                >
                  OUR STORY
                </Subtitles>{" "}
                <img
                  src={seperator_img}
                  alt=""
                  height={13}
                  width={100}
                  className="mt-2"
                />
                <Title
                  font="60px"
                  fontFamily={fontfamily.fontFamilyForum}
                  lineHeight="1.20em"
                  fontWeight={400}
                  color={colors?.white}
                  className="mt-4"
                >
                  Every Flavor <br /> Tells a Story
                </Title>
                <Subtitles
                  font="16px"
                  fontFamily={fontfamily.fontFamilyDMS}
                  lineHeight="30px"
                  fontWeight={400}
                  textAlign="center"
                  color={colors?.white}
                  className="mt-2 w-50"
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto
                  placeat aliquam sed? Nostrum sit in, harum dolorum nemo, dolor
                  ipsum, deleniti quasi obcaecati consectetur voluptatem!
                  Suscipit error cupiditate nulla vel.
                </Subtitles>
                <PrimaryButton text="Read More" onClick={() => {}} />
              </TitleWrapper>
            </ColumnWrapper>
            <ColumnWrapper></ColumnWrapper>
          </RowWrapper>
        </SectionContainer>
      </ThirdSectionWrapper>
      <Footer/>
    </PageWrapper>
  );
};

export default Home;
