import styled from "styled-components";
import { colors, fontfamily } from "../../const/theme";

export const ButtonStyle = styled.button`
  appearance: none;
  background-color: transparent;
  border-width: 0;
  border: 2px solid ${colors.primary};
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
  font-family: ${fontfamily.fontFamilyDMS};
  padding: 15px 45px;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  outline: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(0.694, 0, 0.335, 1),
    background-color 100ms cubic-bezier(0.694, 0, 0.335, 1),
    color 100ms cubic-bezier(0.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  &::before {
    animation: opacityFallbackOut 0.5s step-end forwards;
    backface-visibility: hidden;
    background-color: ${colors.primary};
    clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition: clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1),
      -webkit-clip-path 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
  }
  &:hover:before {
    animation: opacityFallbackIn 0s step-start forwards;
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
  }
  &::after {
    background-color: #ffffff;
  }
  span {
    z-index: 1;
    position: relative;
  }
`;
