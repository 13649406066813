import { ButtonStyle } from "./subcomponents";

interface Buttonprops {
  text: string;
  onClick: () => void;
  className?:string
}

const PrimaryButton = ({ text, onClick ,className}: Buttonprops) => {
  return (
    <ButtonStyle onClick={onClick} className={className}>
      <span>{text}</span>
    </ButtonStyle>
  );
};

export default PrimaryButton;
