import React, { ReactElement } from "react";
import {
  PageWrapper,
  ImageBannerContainer,
  ImageOpacityReducer,
  Header,
  BannerWrapper,
  SectionContainer,
} from "../../components";
import { Subtitles, Title, TitleWrapper } from "../../typography";
import seperator_img from "../../assests/separator.png";
import banner_image from "../../assests/banner_img/menu_banner.jpg";
import { fontfamily, colors } from "../../const/theme";
import { MenuCardContainer, MenuWrapper } from "./subcomponents";
import MenuCards from "./helpers";
import Footer from "../Footer";
import Image from "../../assests/menu/menu-image-1.jpg";

const Menu = (): ReactElement => {
  return (
    <PageWrapper>
      <Header />{" "}
      <BannerWrapper>
        <ImageBannerContainer background={banner_image}>
          <ImageOpacityReducer />
          <TitleWrapper margin="140px 0 0 ">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              SIP, SAVOR, & RELAX
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mb-2"
            />
            <Title
              font="75px"
              lineHeight="1"
              color="#fff"
              fontWeight={500}
              fontFamily={fontfamily.fontFamilyForum}
            >
              Our Menu
            </Title>
          </TitleWrapper>
        </ImageBannerContainer>
      </BannerWrapper>
      <MenuWrapper>
        <SectionContainer>
          <TitleWrapper margin="0">
            <Subtitles
              font="12px"
              fontFamily={fontfamily.fontFamilyDMS}
              letterSpacing="4px"
              fontWeight={700}
              color={colors?.primary}
            >
              SPECIAL DISHES
            </Subtitles>{" "}
            <img
              src={seperator_img}
              alt=""
              height={13}
              width={100}
              className="mt-2"
            />
            <Title
              font="50px"
              fontFamily={fontfamily.fontFamilyForum}
              lineHeight="1.20em"
              fontWeight={500}
              color={colors?.white}
              className="mt-4"
            >
              Delicious Special Menu
            </Title>
          </TitleWrapper>{" "}
          <MenuCardContainer>
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
            <MenuCards
              dishImg={Image}
              dishName={"Green Salad"}
              dishPrice={"199.00"}
              dishDes={
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga quis ..."
              }
            />
          </MenuCardContainer>
        </SectionContainer>
      </MenuWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Menu;
