import styled from "styled-components";
import { colors, fontfamily } from "../../const/theme";
import { FormControl } from "react-bootstrap";

export const ContactWrapper = styled.div`
  background-color: #171819;
  box-shadow: 0 0 0 0 ${colors.white};
  padding: 120px 0;
  position: relative;
`;

export const ContactContainer = styled.div`
  background-color: rgb(14, 13, 12);
  padding: 60px;
`;

export const InputFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin:0 auto;
  flex-direction: column;
`;

export const InputForm = styled(FormControl)`
  margin: 10px 0 0;
  background-color: rgb(26, 27, 28);
  font-size: 16px;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px 1px 1px 1px;
  border-radius: 0px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  height: 56px;
  font-family: ${fontfamily.fontFamilyDMS};
  width: 450px;
  &::placeholder {
    color: lightgray;
    font-family: ${fontfamily.fontFamilyDMS};
  }
  &:focus {
    background-color: rgb(26, 27, 28);
    color: #fff;
  }
  &:active {
    color: #fff;
  }
`;
