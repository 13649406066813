import React, { ReactElement } from "react";
import {
  Anchortext,
  CenterMenu,
  Container,
  ContatcDetialsWrapper,
  HeaderWrapper,
  LogoWrapper,
  Navbar,
  OrderList,
} from "./subComponents";
import { HeaderProsp } from "./types";
import brand_image from "../../assests/brand.png";
import { useNavigate } from "react-router";

const Header = ({
  scrollPosition,
  background,
  height,
  position,
  color,
}: HeaderProsp): ReactElement => {
  const navigate = useNavigate();
  return (
    <HeaderWrapper>
      <ContatcDetialsWrapper>
        <span>
          <p>
            <i className="bx bxs-location-plus"></i>760 Panorama Ct, Aurora, IL
            -60502, US
          </p>
          <p>
            <i className="bx bx-time"></i>Daily : 8.00 am to 10.00 pm
          </p>
        </span>{" "}
        <span>
          <p>
            {" "}
            <i className="bx bx-phone"></i>+1 (630) 364 3926
          </p>
          <p>
            {" "}
            <i className="bx bx-envelope"></i>Info@nammakitchen.us
          </p>
        </span>
      </ContatcDetialsWrapper>
      <Navbar
        position={position}
        height={height}
        background={background}
        scrollPosition={scrollPosition}
      >
        <Container>
          <LogoWrapper>
            <img src={brand_image} alt="res_logo" />
          </LogoWrapper>
          <CenterMenu>
            <OrderList>
              <li
                onClick={() => {
                  navigate("/");
                }}
              >
                <Anchortext>Home</Anchortext>
              </li>{" "}
              <li
                onClick={() => {
                  navigate("/menu-lists");
                }}
              >
                <Anchortext>MENUS</Anchortext>
              </li>{" "}
              <li
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                <Anchortext color={color}>ABOUT US</Anchortext>
              </li>{" "}
              <li
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                <Anchortext>CONTACT</Anchortext>
              </li>
            </OrderList>
          </CenterMenu>
          <div></div>
        </Container>
      </Navbar>
    </HeaderWrapper>
  );
};

export default Header;
